<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Code Parrainage",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Code Parrainage",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Parrainage",
          href: "/",
        },
        {
          text: "Code Parrainage",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        code: "",
        reduction: 0,
        dateLimite: new Date().toLocaleDateString(),
        birthday: false,
        moyensTransport: [],
        typesDistance: [],
        experience: [],
      },
      codeParrainageFormModif: {
        id: 0,
        code: "",
        user: "",
        etat: "",
        nombrefilleul: 0,
      },
      parrainRow: null,
      totalRows: "0",
      currentPage: 1,
  
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "code", sortable: true },
        { key: "user", sortable: true, label: "Utilisateur" },
        {
          key: "nombrefilleul",
          sortable: true,
          label: "Inscription de filleuls",
        },
        { key: "used", sortable: true, label: "Filleuls actifs" },
        { key: "waiting", sortable: true, label: "Paiement en attente" },
        { key: "etat", sortable: true, label: "Etat" },
        { key: "dateCreation", sortable: true, label: "Date de création" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showActive: false,
      erreur: false,
      showRecharge: false,
      headerBgVariant: "warning",
      headerTextVariant: "info",
      bodyBgVariant: "light",
      showpopGainParrain: false,
      showpopGainMembre: false,
      gain_parrain_membre: {
        parrain: 0,
        membre: 0,
      },
      new_parrain: null,
      new_gain: null,
      startValue: 1,
      endValue: 500,
      parbon:500,
      totalValue: "0",
      userChargeLoader: false,
      newDataParrainage: [],
      recherche:""
    };
  },
  validations: {},

  mounted() {
    this.init();
    this.getCommande();
  },
  methods: {
    async init() {

      const listCodeParrainage = await apiRequest(
        "GET",
        "parrains",
        undefined,
        false
      );

      if (listCodeParrainage && listCodeParrainage.data) {
        this.totalRows = listCodeParrainage.data.total
        this.totalValue = listCodeParrainage.data.total
        const codeParrainageFormatTable = listCodeParrainage.data.parrains.map(
          (promo) => {
            return {
              id: promo.id,
              code: promo.code,
              userId: promo.user.id,
              user:
                promo.user && promo.user.personne
                  ? promo.user.personne.firstname[0] +
                    ". " +
                    promo.user.personne.lastname
                  : "",
              nombrefilleul: promo.filleul,
              dateCreation: new Date(promo.createdAt).toLocaleString("fr-FR"),
              etat: promo.actif,
              waiting: promo.waiting,
              used: promo.used,
            };
          }
        );
        this.codePromo = codeParrainageFormatTable;
this.totalRows=codeParrainageFormatTable.length

 
      }
    },
    async getCommande() {
      const dataGaint = await apiRequest("GET", "valeur/parrain", false);

      if (dataGaint && dataGaint.data) {
        this.gain_parrain_membre.parrain = dataGaint.data.parrain;
        this.gain_parrain_membre.membre = dataGaint.data.membre;
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.codeParrainageFormModif.code = row.code;
      this.codeParrainageFormModif.id = row.id;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.codePromoRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    activeRow(row) {
      this.showActive = true;
      this.codePromoRowInfo = row;
    },
    closeModal() {
      this.showDelete = false;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },
    async submitcodeParrainageFormModif() {
      let formData = new FormData();
      formData.append("id", this.codeParrainageFormModif.id);
      formData.append("code", this.codeParrainageFormModif.code);
      const returncodePromo = await apiRequest(
        "PUT",
        "parrain",
        formData,
        true
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
        this.showEdit = false;



        this.$toast.success("Information  modifiée avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    },
    async desactivepromo() {
      this.showdisable = false;
      const returncodePromo = await apiRequest(
        "POST",
        "parrain/action",
        { id: this.codePromoRowInfo.id, action: 0 },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async activePromo() {
      this.showActive = false;
      const returncodePromo = await apiRequest(
        "POST",
        "parrain/action",
        { id: this.codePromoRowInfo.id, action: 1 },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
        this.$snotify.info("Information activée avec succès", "Fait");
      }
    },
    async deletepromo() {
      this.showDelete = false;
      const returncodePromo = await apiRequest(
        "POST",
        "parrain/action",
        { id: this.codePromoRowInfo.id, action: 0 },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
        this.$snotify.warning("Information désactivée avec succès", "Fait");
      }
    },
    rechargeRow(row) {
      this.showRecharge = true;
      this.parrainRow = row;
    },
    async recharge() {
      this.showRecharge = false;
      const charge = await apiRequest(
        "POST",
        "parrain/recharge",
        { idUser: this.parrainRow.userId },
        false
      );
      if (charge && charge.data) {



        this.$toast.success("Recharge effectuée avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      }
    },
    changeGaingMembre() {
      this.showpopGainMembre = true;
      this.new_gain = this.gain_parrain_membre.membre;
    },
    changeGainParrain() {
      this.showpopGainParrain = true;
      this.new_parrain = this.gain_parrain_membre.parrain;
    },

    async submitParrain() {
      const parrainData = await apiRequest(
        "POST",
        "valeur/parrain",
        {
          key: "parrain-gain",
          value: this.new_parrain,
        },
        false
      );
      if (parrainData && parrainData.data) {
        //console.log("codePromo:", returncodePromo.data);


        this.$toast.success("Gain des parrains modifié avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

        this.init();

        this.getCommande();
      } else {
        this.$toast.success("Gain des parrains non modifié", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
      this.showpopGainParrain = false;
    },
    async submitMembre() {
      const membreData = await apiRequest(
        "POST",
        "valeur/parrain",
        {
          key: "membre-gain",
          value: this.new_gain,
        },
        false
      );
      if (membreData && membreData.data) {
        //console.log("codePromo:", returncodePromo.data);
     

        this.$toast.success("Gain des membres modifié", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

        this.init();

        this.getCommande();
      } else {
 

        this.$toast.error("Gain des membres non modifié", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
      this.showpopGainMembre = false;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    async actifpage(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "parrains?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const codeParrainageFormatTable = listeWallet.data.parrains.map(
          (promo) => {
            return {
              id: promo.id,
              code: promo.code,
              userId: promo.user.id,
              user:
                promo.user && promo.user.personne
                  ? promo.user.personne.firstname[0] +
                    ". " +
                    promo.user.personne.lastname
                  : "",
              nombrefilleul: promo.filleul,
              dateCreation: new Date(promo.createdAt).toLocaleString("fr-FR"),
              etat: promo.actif,
              waiting: promo.waiting,
              used: promo.used,
            };
          }
        );
     
      
        this.newDataParrainage = codeParrainageFormatTable;
        this.userChargeLoader = false;
        this.totalRows = codeParrainageFormatTable.length
  
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "parrains/recherche",
       { mots:this.recherche},
      


        false
      );
      if (dataSearch && dataSearch.data) {
        const codeParrainageFormatTableSearch = dataSearch.data[0].map(
          (promo) => {
            return {
              id: promo.id,
              code: promo.code,
              userId: promo.user.id,
              user:
                promo.user && promo.user.personne
                  ? promo.user.personne.firstname[0] +
                    ". " +
                    promo.user.personne.lastname
                  : "",
              nombrefilleul: promo.filleul,
              dateCreation: new Date(promo.createdAt).toLocaleString("fr-FR"),
              etat: promo.actif,
              waiting: promo.waiting,
              used: promo.used,
            };
          }
        );

        this.newDataParrainage = codeParrainageFormatTableSearch;
        this.userChargeLoader = false;
        this.totalRows = codeParrainageFormatTableSearch.length

      }
    },

 


    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parbon;
      this.endValue -= this.parbon;
     
      this.actifpage(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parbon;
      this.endValue += this.parbon;
    
      this.actifpage(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parbon;
      this.endValue = this.totalValue;
     
      this.actifpage(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parbon;
     
      this.actifpage(this.startValue, this.endValue);
    },
   











  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 col-xl-6">
            <div class="card mb-3 bg-secondary-special">
              <div class="widget-wrapping">
                <div class="widget-content-left">
                  <div class="widget-heading thesize">Gain</div>
                  <div class="widget-subheading typeParrain">
                    Pour les parrains
                  </div>
                </div>
                <div>
                  <div class="widget-numbers text-white gain">
                    <div
                      class="spinner-border text-light"
                      role="status"
                      v-if="gain_parrain_membre.parrain == 0"
                    >
                      <span class="sr-only">...</span>
                    </div>
                    <span v-if="gain_parrain_membre.parrain != 0">{{
                      gain_parrain_membre.parrain
                    }}</span>
                  </div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-white">
                    <i
                      class="fa fa fa-edit"
                      aria-hidden="true"
                      id="tooltip-target-2"
                      @click="changeGainParrain()"
                    ></i>
                    <b-tooltip target="tooltip-target-2" triggers="hover">
                      Modifier la valeur du <strong>gain</strong> des parrains
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-6">
            <div class="card mb-3 bg-secondary-smile">
              <div class="widget-wrapping">
                <div class="widget-content-left">
                  <div class="widget-heading thesize">Gain</div>
                  <div class="widget-subheading typeParrain">
                    Pour les membres
                  </div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-white">
                    <div
                      class="spinner-border text-light"
                      role="status"
                      v-if="gain_parrain_membre.membre == 0"
                    >
                      <span class="sr-only">...</span>
                    </div>
                    <span v-if="gain_parrain_membre.membre != 0" class="gain">{{
                      gain_parrain_membre.membre
                    }}</span>
                  </div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-white">
                    <i
                      class="fa fa fa-edit"
                      aria-hidden="true"
                      id="tooltip-target-1"
                      @click="changeGaingMembre()"
                    ></i>
                    <b-tooltip target="tooltip-target-1" triggers="hover">
                      Modifier la valeur du <strong>gain</strong> des membres
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">


          <div class="containerDot" v-if="codePromo.length == 0 ">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
         

            <div
              class="row d-flex justify-content-between  p-2"
            >
            <div class="card-title">

            </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des codes de parrainage</a
                    >
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Total code de parrainage&nbsp;
                         {{  totalRows }}
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="recherche"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="newDataParrainage.length!=0?newDataParrainage:codePromo"
                      :fields="fields"
                      responsive="sm"
            
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                    
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(etat)="row">
                        <span
                          v-if="row.item.etat == true"
                          style="
                            padding: 0.25rem 1.2rem;
                            background-color: #4caf50;
                            border-radius: 1.3rem;
                            width: 100% !important;
                            height: 14px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          Actif
                        </span>
                        <span
                          v-if="row.item.etat == false"
                          style="
                            padding: 0.25rem 0.89rem;
                            background-color: #ff5252;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          Inactif
                        </span>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a
                          v-if="row.item.etat == true"
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="désactiver"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          @click="activeRow(row.item)"
                          v-b-tooltip.hover
                          v-if="row.item.etat == false"
                          title="activer"
                        >
                          <i class="fas fa-trash-restore font-size-15"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a
                          v-if="row.item.waiting != 0"
                          href="javascript:void(0);"
                          class="text-warning"
                          v-b-tooltip.hover
                          @click="rechargeRow(row.item)"
                          title="Recharge"
                        >
                          <i class="fa fa-money-bill font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment désactiver ce code de parrainage ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showActive"
      title="Voulez-vous vraiment réactiver ce code de parrainage ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activePromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer code parrainage"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitcodeParrainageFormModif"
      >
        <div class="form-group">
          <label for="tite">Code parrainage</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="code"
            v-model="codeParrainageFormModif.code"
          />
        </div>

        <div class="form-group alignBtn">
          <button class="btn btn-secondary">Annuler</button>
          <button @click="closeModal" class="btn btn-primary ml-3">
            Modifier
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showRecharge"
      title="Voulez-vous vraiment recharger cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showRecharge = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="recharge"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showpopGainParrain"
      title="Modifier le gain des parrains"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col cols="3">Gain</b-col>
          <b-col>
            <b-form-input class="form-controle" v-model="new_parrain">
            </b-form-input>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            @click="showpopGainParrain = false"
          >
            Annuler
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="ml-2"
            @click="submitParrain"
          >
            Valider
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showpopGainMembre"
      title="Modifier le gain des membres"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1 d-flex align-items-center">
          <b-col cols="2" style="font-size: 18px">Gain</b-col>
          <b-col>
            <b-form-input class="form-controle" v-model="new_gain">
            </b-form-input>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            @click="showpopGainMembre = false"
          >
            Annuler
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="ml-2"
            @click="submitMembre"
          >
            Valider
          </b-button>
        </div>
      </template>
    </b-modal>
  </Layout>
</template>
<style scoped>
.alignBtn {
  display: flex;
  float: right;

  align-items: flex-end;
}

.bg-secondary-special {
  background: #3AAA35;
}

.bg-secondary-smile {
  background: #3AAA35;
}

.widget-wrapping {
  display: flex !important;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.thesize {
  font-size: 2em;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.typeParrain {
  margin-top: 0.444rem;
  font-size: 17px;
  color: #fff;
}

.gain {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.fa-edit {
  font-size: 17px;
  cursor: pointer;
}




thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;

}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

</style>
